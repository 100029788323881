/* @import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Protest+Strike&family=Seymour+One&family=Young+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Creepster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ceviche+One&family=Creepster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ceviche+One&family=Creepster&family=Flavors&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Risque&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Piedra&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Joti+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slide-in {
  animation: slideIn 0.5s ease-out;
}

/* Add this to your CSS file */
.gradient-background {
  background: linear-gradient(to right, yellow, red);
}

/* Styling the wheel */
#wheel {
  width: 5cm;
  height: 5cm;
  border: 5px solid gold; /* This creates the outer circle (wheel) */
  border-radius: 50%; /* Makes the div a circle */
  position: relative;
  margin: 20px auto;
  animation: spin infinite linear; /* Infinite rotation animation */
  transform-origin: center;
}

/* Line in the center */
#wheel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: red;
  transform: translateX(-50%);
}

/* Rotation animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card:hover {
  cursor: pointer; /* Changes the cursor */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Adds a shadow on hover */
  transform: scale(1.02); /* Slightly increases the size on hover */
  transition: transform 0.2s ease-in-out; /* Smooth animation effect */
}
