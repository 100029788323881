body {
  /* font-family: Arial, sans-serif; */
  /* background: #f0f8ff; */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; Center visualization vertically */
  box-sizing: border-box;
}

.app2 {
  text-align: center;
  width: 100%;
  max-width: 100%; /* Ensure the app container doesn't overflow */
  padding: 10px; /* Add some padding for smaller devices */
}

.controls {
  margin-bottom: 20px;
}

.visualization {
  /* display: flex; */
  /* justify-content: center; Center horizontally */
  /* align-items: center; Center vertically */
  height: 80vw; /* Scale the height based on the viewport width */
  width: 80vw; /* Scale the width proportionally */
  max-height: 500px; /* Ensure the visualization doesn't get too large on wider screens */
  max-width: 500px; /* Maintain consistent maximum size */
  margin: 0 auto;
  position: relative; /* Allows positioning of child elements like Earth */
}

.earth-container {
  position: absolute;
  height: 300px;
  width: 300px;
  animation: rotate 4s linear infinite;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent; /* Gradient border */
}

.user {
  position: absolute;
}

.head {
  z-index: 10;
}

.body {
  z-index: 10;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .controls {
    font-size: 14px; /* Smaller text size for controls */
    margin-bottom: 15px;
  }

  select {
    font-size: 14px;
    padding: 5px;
  }

  .visualization {
    height: 90vw; /* Increase height proportionally for smaller screens */
    width: 90vw;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
